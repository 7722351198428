import React from "react"

export const ExperienceDesign = ({ className = "" }) => (
  <div className={className}>
    <svg
      id="ab0c8d57-73b5-4efc-a896-a2305a232bd8"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="auto"
      viewBox="0 0 1027.4958 696.05876"
    >
      <title>experience design</title>
      <circle
        cx="696.2479"
        cy="355.70168"
        r="76.7521"
        fill="#38d39f"
        opacity="0.4"
      />
      <rect x="270" y="461.90193" width="428" height="116" fill="#3f3d56" />
      <path
        d="M756.83869,276.47654l-17.08989,8.08834,15.1253-11.3099a85.35244,85.35244,0,0,0-8.25839-10.86725L672.499,280.80982l66.94832-25.53848a85.33888,85.33888,0,0,0-9.72181-7.41015L616.789,266.0523a84.63924,84.63924,0,0,0-18.53688,52.82025h170A84.59367,84.59367,0,0,0,756.83869,276.47654Z"
        transform="translate(-86.2521 -101.97062)"
        fill="#f2f2f2"
      />
      <path
        d="M770.37527,152.166A495.97179,495.97179,0,0,0,552.2521,101.97062c-153.82965,0-291.36139,69.75269-382.71033,179.34869C325.96817,247.04484,598.319,187.71378,770.37527,152.166Z"
        transform="translate(-86.2521 -101.97062)"
        fill="#f2f2f2"
      />
      <rect y="693.90193" width="1020" height="2" fill="#2f2e41" />
      <path
        d="M682.11649,318.87255,624.2521,232.92193s87.63109,30.59259,77.68592,85.95062Z"
        transform="translate(-86.2521 -101.97062)"
        fill="#3f3d56"
      />
      <path
        d="M684.38771,318.87255l57.86439-85.95062s-87.63109,30.59259-77.68591,85.95062Z"
        transform="translate(-86.2521 -101.97062)"
        fill="#38d39f"
      />
      <path
        d="M784.7521,797.87255h-429v-640h429Zm-427-2h425v-636h-425Z"
        transform="translate(-86.2521 -101.97062)"
        fill="#2f2e41"
      />
      <rect x="271" y="71.08308" width="427" height="2" fill="#2f2e41" />
      <circle cx="282" cy="63.90193" r="4" fill="#38d39f" />
      <circle cx="293" cy="63.90193" r="4" fill="#38d39f" />
      <circle cx="304" cy="63.90193" r="4" fill="#38d39f" />
      <rect x="286" y="84.90193" width="18" height="10" fill="#38d39f" />
      <rect x="286" y="163.90193" width="88" height="10" fill="#38d39f" />
      <rect
        x="685.2521"
        y="186.87255"
        width="88"
        height="10"
        transform="translate(1372.2521 281.77448) rotate(-180)"
        fill="#f2f2f2"
      />
      <rect x="286" y="180.90193" width="148" height="6" fill="#f2f2f2" />
      <rect x="286" y="193.90193" width="140" height="6" fill="#f2f2f2" />
      <rect x="286" y="206.90193" width="102" height="6" fill="#f2f2f2" />
      <rect x="320" y="384.90193" width="58" height="6" fill="#f2f2f2" />
      <rect x="320" y="397.90193" width="54.86486" height="6" fill="#f2f2f2" />
      <rect x="320" y="410.90193" width="39.97297" height="6" fill="#f2f2f2" />
      <rect x="455" y="384.90193" width="58" height="6" fill="#f2f2f2" />
      <rect x="455" y="397.90193" width="54.86486" height="6" fill="#f2f2f2" />
      <rect x="455" y="410.90193" width="39.97297" height="6" fill="#f2f2f2" />
      <rect x="595" y="384.90193" width="58" height="6" fill="#f2f2f2" />
      <rect x="595" y="397.90193" width="54.86486" height="6" fill="#f2f2f2" />
      <rect x="595" y="410.90193" width="39.97297" height="6" fill="#f2f2f2" />
      <rect x="410" y="611.90193" width="88" height="10" fill="#38d39f" />
      <rect x="410" y="628.90193" width="148" height="6" fill="#f2f2f2" />
      <rect x="410" y="641.90193" width="140" height="6" fill="#f2f2f2" />
      <rect x="410" y="654.90193" width="102" height="6" fill="#f2f2f2" />
      <rect
        x="312.5"
        y="271.90193"
        width="348"
        height="2"
        fill="#38d39f"
        opacity="0.3"
      />
      <rect
        x="312"
        y="306.90193"
        width="74"
        height="66"
        fill="#38d39f"
        opacity="0.3"
      />
      <rect x="447" y="306.90193" width="74" height="66" fill="#3f3d56" />
      <rect x="587" y="306.90193" width="74" height="66" fill="#3f3d56" />
      <path
        d="M560.61073,474.59512l-.97627-.01907c.021-1.08114.63114-26.56133,10.66219-33.604l.56059.79893C561.23091,448.52955,560.6155,474.33532,560.61073,474.59512Z"
        transform="translate(-86.2521 -101.97062)"
        fill="#38d39f"
      />
      <circle cx="489.20618" cy="333.79786" r="4.88134" fill="#38d39f" />
      <path
        d="M574.47138,453.00423a15.19766,15.19766,0,0,1-7.84149.33859,13.85019,13.85019,0,0,1,14.23985-4.88441A15.19761,15.19761,0,0,1,574.47138,453.00423Z"
        transform="translate(-86.2521 -101.97062)"
        fill="#38d39f"
      />
      <path
        d="M700.61073,474.59512l-.97627-.01907c.021-1.08114.63114-26.56133,10.66219-33.604l.56059.79893C701.23091,448.52955,700.6155,474.33532,700.61073,474.59512Z"
        transform="translate(-86.2521 -101.97062)"
        fill="#38d39f"
      />
      <circle cx="629.20618" cy="333.79786" r="4.88134" fill="#38d39f" />
      <path
        d="M714.47138,453.00423a15.19766,15.19766,0,0,1-7.84149.33859,13.85019,13.85019,0,0,1,14.23985-4.88441A15.19761,15.19761,0,0,1,714.47138,453.00423Z"
        transform="translate(-86.2521 -101.97062)"
        fill="#38d39f"
      />
      <rect x="286" y="498.90193" width="88" height="10" fill="#38d39f" />
      <rect x="286" y="515.90193" width="148" height="6" fill="#f2f2f2" />
      <rect x="286" y="528.90193" width="140" height="6" fill="#f2f2f2" />
      <rect x="286" y="541.90193" width="102" height="6" fill="#f2f2f2" />
      <circle
        cx="611.7521"
        cy="512.70168"
        r="31.2479"
        fill="#38d39f"
        opacity="0.4"
      />
      <circle
        cx="553.92017"
        cy="527.62605"
        r="21.92017"
        fill="#38d39f"
        opacity="0.4"
      />
      <circle cx="167.5" cy="582.40193" r="79" fill="#2f2e41" />
      <rect x="131.5" y="644.40193" width="24" height="43" fill="#2f2e41" />
      <rect x="179.5" y="644.40193" width="24" height="43" fill="#2f2e41" />
      <ellipse cx="151.5" cy="687.90193" rx="20" ry="7.5" fill="#2f2e41" />
      <ellipse cx="199.5" cy="686.90193" rx="20" ry="7.5" fill="#2f2e41" />
      <circle cx="169.5" cy="562.40193" r="27" fill="#fff" />
      <circle cx="169.5" cy="550.40193" r="9" fill="#3f3d56" />
      <path
        d="M177.11943,610.90483c-6.37889-28.56758,14.01185-57.43392,45.544-64.47477s62.2651,10.41,68.644,38.9776-14.51861,39.10379-46.05075,46.14464S183.49832,639.4724,177.11943,610.90483Z"
        transform="translate(-86.2521 -101.97062)"
        fill="#38d39f"
      />
      <ellipse
        cx="313.52848"
        cy="596.95692"
        rx="39.5"
        ry="12.40027"
        transform="translate(-446.20984 527.75787) rotate(-65.07683)"
        fill="#2f2e41"
      />
      <ellipse
        cx="168.52848"
        cy="620.95692"
        rx="12.40027"
        ry="39.5"
        transform="translate(-357.17251 49.01394) rotate(-27.89329)"
        fill="#2f2e41"
      />
      <ellipse
        cx="906.25518"
        cy="625.62283"
        rx="12.40027"
        ry="39.5"
        transform="translate(-250.61562 265.81898) rotate(-21.07832)"
        fill="#2f2e41"
      />
      <circle cx="893.18955" cy="583.10381" r="79" fill="#2f2e41" />
      <rect
        x="991.67544"
        y="747.00687"
        width="24"
        height="43"
        transform="matrix(-1, 0.0028, -0.0028, -1, 1923.24743, 1432.22882)"
        fill="#2f2e41"
      />
      <rect
        x="943.67563"
        y="747.14132"
        width="24"
        height="43"
        transform="translate(1827.24837 1432.63217) rotate(179.83951)"
        fill="#2f2e41"
      />
      <ellipse
        cx="995.7371"
        cy="790.5292"
        rx="20"
        ry="7.5"
        transform="translate(-88.46246 -99.17847) rotate(-0.16049)"
        fill="#2f2e41"
      />
      <ellipse
        cx="947.73448"
        cy="789.66365"
        rx="20"
        ry="7.5"
        transform="translate(-88.46023 -99.31292) rotate(-0.16049)"
        fill="#2f2e41"
      />
      <circle cx="891.13354" cy="563.10949" r="27" fill="#fff" />
      <circle cx="891.13354" cy="563.10949" r="9" fill="#3f3d56" />
      <path
        d="M1055.86824,611.39234c6.29884-28.58533-14.17267-57.39444-45.72441-64.34694s-62.23569,10.58438-68.53454,39.16971,14.62808,39.063,46.17982,46.01548S1049.56939,639.97768,1055.86824,611.39234Z"
        transform="translate(-86.2521 -101.97062)"
        fill="#38d39f"
      />
      <ellipse
        cx="1068.31896"
        cy="648.16898"
        rx="39.5"
        ry="12.40027"
        transform="translate(-261.30169 421.84772) rotate(-25.53475)"
        fill="#2f2e41"
      />
      <path
        d="M1018.32588,713.46562c.02166,7.732-19.84411,23.05568-41.93541,23.11755s-42.86536-21.648-42.887-29.38,20.71728,1.442,42.80858,1.3801S1018.30423,705.73367,1018.32588,713.46562Z"
        transform="translate(-86.2521 -101.97062)"
        fill="#fff"
      />
      <circle
        cx="917.2479"
        cy="468.70168"
        r="110.2479"
        fill="#38d39f"
        opacity="0.4"
      />
      <circle cx="190.40483" cy="348.59022" r="79" fill="#2f2e41" />
      <rect
        x="226.16663"
        y="506.95016"
        width="24"
        height="43"
        transform="translate(11.69174 -135.94422) rotate(10.26106)"
        fill="#2f2e41"
      />
      <rect
        x="273.39893"
        y="515.50057"
        width="24"
        height="43"
        transform="translate(13.97028 -144.22112) rotate(10.26106)"
        fill="#2f2e41"
      />
      <ellipse
        cx="285.68749"
        cy="563.4754"
        rx="7.5"
        ry="20"
        transform="translate(-415.18867 315.35713) rotate(-49.73894)"
        fill="#2f2e41"
      />
      <ellipse
        cx="239.4392"
        cy="555.10313"
        rx="7.5"
        ry="20"
        transform="translate(-425.15907 277.10318) rotate(-49.73894)"
        fill="#2f2e41"
      />
      <circle cx="195.93551" cy="329.26636" r="27" fill="#fff" />
      <circle cx="207.03243" cy="319.5883" r="9" fill="#3f3d56" />
      <path
        d="M214.337,364.61728c-1.188-29.247,24.01865-54.01935,56.30068-55.33066s59.41489,21.335,60.60291,50.582-21.2521,35.89214-53.53414,37.20345S215.525,393.86426,214.337,364.61728Z"
        transform="translate(-86.2521 -101.97062)"
        fill="#38d39f"
      />
      <ellipse
        cx="349.6025"
        cy="436.16834"
        rx="39.5"
        ry="12.40027"
        transform="translate(-174.86437 -12.8362) rotate(-12.9101)"
        fill="#2f2e41"
      />
      <ellipse
        cx="194.52848"
        cy="461.95692"
        rx="39.5"
        ry="12.40027"
        transform="translate(-184.5461 -46.83125) rotate(-12.9101)"
        fill="#2f2e41"
      />
      <path
        d="M254.95954,481.69373a18,18,0,0,0,35.42423,6.41281c1.77085-9.78213-5.79238-13.24363-15.57451-15.01448S256.73039,471.9116,254.95954,481.69373Z"
        transform="translate(-86.2521 -101.97062)"
        fill="#fff"
      />
      <rect
        x="380.7521"
        y="374.37255"
        width="74"
        height="66"
        transform="translate(48.33035 -204.77859) rotate(16.68876)"
        fill="#3f3d56"
      />
      <path
        d="M399.11981,435.94807l-.92967-.29863c.33056-1.02957,8.23224-25.26129,19.86323-29.12666l.30755.92627C407.19918,411.15851,399.199,435.70058,399.11981,435.94807Z"
        transform="translate(-86.2521 -101.97062)"
        fill="#38d39f"
      />
      <circle cx="338.23982" cy="301.05004" r="4.88134" fill="#38d39f" />
      <path
        d="M418.59694,419.247a15.19762,15.19762,0,0,1-7.60842-1.92753,13.85017,13.85017,0,0,1,15.04271-.58938A15.19758,15.19758,0,0,1,418.59694,419.247Z"
        transform="translate(-86.2521 -101.97062)"
        fill="#38d39f"
      />
    </svg>
  </div>
)

export const CreativeTeam = ({ className = "" }) => (
  <div className={className}>
    <svg
      id="f2557a61-56ef-4ee7-9811-4cdcc73688e4"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="auto"
      viewBox="0 0 1121.80595 699.0818"
    >
      <title>creative team</title>
      <circle cx="852.80585" cy="367.99943" r="253.9999" fill="#f2f2f2" />
      <circle cx="137.80597" cy="97.00003" r="97.00003" fill="#ff6584" />
      <circle cx="311.80585" cy="266.99943" r="253.9999" fill="#f2f2f2" />
      <ellipse cx="299.80595" cy="520" rx="279" ry="15" fill="#3f3d56" />
      <ellipse
        cx="243.80595"
        cy="520.51724"
        rx="209"
        ry="10.34483"
        opacity="0.1"
      />
      <ellipse cx="842.80595" cy="624.5" rx="279" ry="16.5" fill="#3f3d56" />
      <ellipse
        cx="786.80595"
        cy="625.06897"
        rx="209"
        ry="11.37931"
        opacity="0.1"
      />
      <ellipse cx="400.90297" cy="586" rx="178.04348" ry="9" fill="#3f3d56" />
      <ellipse
        cx="85.90297"
        cy="556"
        rx="85.90297"
        ry="4.34235"
        fill="#3f3d56"
      />
      <ellipse
        cx="952.92685"
        cy="691"
        rx="159.8791"
        ry="8.0818"
        fill="#3f3d56"
      />
      <rect x="615.80595" y="433" width="320" height="190" fill="#3f3d56" />
      <circle cx="718.80595" cy="530" r="72" fill="#38d39f" />
      <rect x="812.80595" y="463" width="24" height="24" fill="#38d39f" />
      <rect
        x="842.80595"
        y="463"
        width="24"
        height="24"
        fill="#38d39f"
        opacity="0.5"
      />
      <rect
        x="872.80595"
        y="463"
        width="24"
        height="24"
        fill="#38d39f"
        opacity="0.2"
      />
      <rect x="812.80595" y="493" width="24" height="24" fill="#2f2e41" />
      <rect
        x="842.80595"
        y="493"
        width="24"
        height="24"
        fill="#2f2e41"
        opacity="0.5"
      />
      <rect
        x="872.80595"
        y="493"
        width="24"
        height="24"
        fill="#2f2e41"
        opacity="0.2"
      />
      <rect x="812.80595" y="523" width="24" height="24" fill="#ff6584" />
      <rect
        x="842.80595"
        y="523"
        width="24"
        height="24"
        fill="#ff6584"
        opacity="0.5"
      />
      <rect
        x="872.80595"
        y="523"
        width="24"
        height="24"
        fill="#ff6584"
        opacity="0.2"
      />
      <path
        d="M757.903,558.4591v144c39.76453,0,74-32.23553,74-72S797.6675,558.4591,757.903,558.4591Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#3f3d56"
        opacity="0.5"
      />
      <path
        d="M1043.403,546.9591s42,19,27,41-60,64-61,73-7,17-7,17-20-21-18-23,22-21,23-26,31-44,31-44l-27-24Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#ffb9b9"
      />
      <path
        d="M1043.403,546.9591s42,19,27,41-60,64-61,73-7,17-7,17-20-21-18-23,22-21,23-26,31-44,31-44l-27-24Z"
        transform="translate(-39.09703 -100.4591)"
        opacity="0.1"
      />
      <path
        d="M990.403,588.9591s29,12,27,31,35,73,35,73,4,13,3,17,26,3,26,3v-9s-21-36-23-46-14-49-14-49-4-35-24-35A37.5,37.5,0,0,0,990.403,588.9591Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#ffb9b9"
      />
      <path
        d="M991.903,498.4591s60,49,58,53-31,19-36,20-40.37795-73-40.37795-73Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#2f2e41"
      />
      <path
        d="M991.903,498.4591s60,49,58,53-31,19-36,20-40.37795-73-40.37795-73Z"
        transform="translate(-39.09703 -100.4591)"
        opacity="0.1"
      />
      <path
        d="M926.903,508.4591s-6,27,10,38,43,54,46,54,46-23,44-27-24-39-24-39l-7-37S934.903,495.4591,926.903,508.4591Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#2f2e41"
      />
      <path
        d="M991.903,420.4591l9,58,16,41s24,25,10,27-22-27-22-27l-13-24Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#ffb9b9"
      />
      <path
        d="M991.903,420.4591l9,58,16,41s24,25,10,27-22-27-22-27l-13-24Z"
        transform="translate(-39.09703 -100.4591)"
        opacity="0.1"
      />
      <path
        d="M958.403,355.9591s0,21-7,23,28,29,28,29-4-35,10-44Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#ffb9b9"
      />
      <path
        d="M958.403,355.9591s0,21-7,23,28,29,28,29-4-35,10-44Z"
        transform="translate(-39.09703 -100.4591)"
        opacity="0.1"
      />
      <circle cx="935.30595" cy="249.5" r="23" fill="#ffb9b9" />
      <path
        d="M975.403,397.9591s-21-26-24-25-25,23-27,30-6,57-3,67,3,36,2,38-7,7-1,7,27-15,41-12,33-1,34-3-1-54,0-58-2-24-3-28-1-20-11-24l-4.264-.42136-.23882,9.89157Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#38d39f"
      />
      <path
        d="M931.403,449.9591s2,31,17,44,47,35,49,39,21,25,25,16a14.96181,14.96181,0,0,0,.80194-8.86675,22.05314,22.05314,0,0,0-9.90344-14.41279l-8.8985-5.72046-44-46-2-24Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#ffb9b9"
      />
      <path
        d="M988.903,651.4591s-11.5-2.5-15.5,4.5,4,26,4,26,23,48,27,42-3-28-3-28,10-22,6-23S988.903,663.4591,988.903,651.4591Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#2f2e41"
      />
      <path
        d="M1059.403,708.9591s-6-3-7,0-7,18,0,20,76,4,74,0-13-9-20-11-25-17-25-17S1071.403,713.9591,1059.403,708.9591Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#2f2e41"
      />
      <path
        d="M997.57221,331.70484a6.15938,6.15938,0,0,0-2.67142-3.48695,14.13565,14.13565,0,0,0-.564-4.60169c-1.46952-4.34444-4.95079-4.09542-8.23546-6.01618-1.42509-.83335-1.33707-1.56988-2.07894-2.93864a10.90653,10.90653,0,0,0-4.20254-4.28786c-2.10732-1.34264-4.3318-2.72537-6.82474-2.8953-5.239-.35709-9.25573,4.57054-14.263,6.152-2.2071.697-4.57869.72578-6.79744,1.38476s-4.43236,2.23247-4.763,4.52325c-.15265,1.05758.11666,2.14252-.03817,3.19981-.30781,2.10139-2.15145,3.57314-3.38563,5.30155-2.72923,3.82216-2.41707,9.22181-.23451,13.38047a18.80958,18.80958,0,0,0,1.57379,2.46278,15.14954,15.14954,0,0,0,1.66167,5.62586c2.18256,4.15865,5.93822,7.26624,9.86148,9.84805a23.277,23.277,0,0,1-.58191-4.19944,2.89682,2.89682,0,0,1,.17857-1.39142c.43691-.91332,1.59141-1.15622,2.47166-1.65643,1.8232-1.03606,2.53817-3.32891,2.59089-5.42527s-.39154-4.19618-.1719-6.28163a3.43233,3.43233,0,0,1,1.175-2.52484,4.05855,4.05855,0,0,1,2.21524-.52815c3.73556-.1524,7.63864-.221,10.93363-1.98755a16.20347,16.20347,0,0,1,3.40312-1.7215c2.80021-.73022,5.66219,1.23245,7.276,3.63455s2.41844,5.26063,3.90742,7.74207,4.0613,4.69432,6.94016,4.40032a1.15205,1.15205,0,0,0,.85976-.38231,1.28879,1.28879,0,0,0,.15838-.73249l.16085-11.00458A16.63363,16.63363,0,0,0,997.57221,331.70484Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#2f2e41"
      />
      <path
        d="M940.403,398.9591s22,10,21,31,6,23-11,25-21,0-22,2-7-46-3-51S940.403,398.9591,940.403,398.9591Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#38d39f"
      />
      <circle cx="458.80595" cy="97" r="32" fill="#2f2e41" />
      <path
        d="M519.403,547.9591l-18,49s-15,12-6,16,27-4,27-4l14-36-2-25Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#2f2e41"
      />
      <path
        d="M519.403,547.9591l-18,49s-15,12-6,16,27-4,27-4l14-36-2-25Z"
        transform="translate(-39.09703 -100.4591)"
        opacity="0.1"
      />
      <path
        d="M520.903,257.4591l15,2s7,55,4,59-18,20-24,16S520.903,257.4591,520.903,257.4591Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#575a89"
      />
      <path
        d="M377.403,357.9591s-21,9-25,10-10,11-3,13,34-18,34-18Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#a0616a"
      />
      <path
        d="M526.403,567.9591l11,41s1,15,6,16,20-5,20-7-6-18-6-18,0-12-6-17-11.40551-21.74959-11.40551-21.74959Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#2f2e41"
      />
      <path
        d="M540.403,473.9591c2.02,4.71,1.77,38.88-.43994,61.62-1.07007,11.05-2.60009,19.4-4.56006,20.38l-14.33007-3.58-5.66993-1.42-.15-12.44-.85-68.56-17-71v-18l43,4,6.82007,10.71.17993.29S537.403,466.9591,540.403,473.9591Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#2f2e41"
      />
      <path
        d="M531.403,495.9591c2.14,3.21,5.71,22.43,8.56006,39.62-1.07007,11.05-2.60009,19.4-4.56006,20.38l-14.33007-3.58c-.22-.46-.43994-.94-.66993-1.42-1.83007-3.84-3.55-8.08-5.15-12.44l-.85-68.56-17-71v-18l43,4,6.82007,10.71-39.82007-2.71,9,39,11,41S527.403,489.9591,531.403,495.9591Z"
        transform="translate(-39.09703 -100.4591)"
        opacity="0.1"
      />
      <path
        d="M460.403,340.9591s-14,31,6,74a874.08921,874.08921,0,0,1,33,82s7,33,17,54,8,22,8,22l16-9s-9-62-13-68-4-23-4-23l-11-41-9-39,44,3s0-57-20-68Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#2f2e41"
      />
      <path
        d="M476.903,212.4591s0,16-5,17-15,1-18,6,23,71,23,71l27-17,9-45s-13-8-13-12v-18Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#a0616a"
      />
      <path
        d="M476.903,212.4591s0,16-5,17-15,1-18,6,23,71,23,71l27-17,9-45s-13-8-13-12v-18Z"
        transform="translate(-39.09703 -100.4591)"
        opacity="0.1"
      />
      <circle cx="453.30595" cy="105.5" r="24" fill="#a0616a" />
      <path
        d="M479.903,286.4591s-9-57-11-57-30,6-32,7-9,10-9,10l14,40s6,28,10,33,12,19,8,21-8,5-4,5,37-6,39-3,35-11,35-12-4-48-4-48l10-23-30-21Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#575a89"
      />
      <path
        d="M437.903,241.4591l-10,5s-28,64-30,65-12,20-13,24-3,17-6,19a51.953,51.953,0,0,1-5,3s6,10,9,10,13-14,13-14,22,14,28,3,21-81,21-81Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#575a89"
      />
      <path
        d="M516.403,271.9591s-14-34-22-30,6,28,15,32Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#a0616a"
      />
      <path
        d="M502.403,272.9591l14.03051-3.17174L519.403,284.9591s13,23,10,33-21,26-27,17-11-23-7-31S505.403,276.9591,502.403,272.9591Z"
        transform="translate(-39.09703 -100.4591)"
        opacity="0.1"
      />
      <path
        d="M505.403,270.9591l14.03051-3.17174L522.403,282.9591s13,23,10,33-21,26-27,17-11-23-7-31S508.403,274.9591,505.403,270.9591Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#575a89"
      />
      <ellipse
        cx="498.90297"
        cy="190.9591"
        rx="11.5"
        ry="24"
        transform="translate(75.23657 461.27749) rotate(-64.8987)"
        fill="#2f2e41"
      />
      <circle cx="477.80595" cy="70" r="14" fill="#2f2e41" />
      <path
        d="M503.903,165.4591a13.99971,13.99971,0,0,1,12.5-13.91724,14,14,0,1,0,0,27.83448A13.99971,13.99971,0,0,1,503.903,165.4591Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#2f2e41"
      />
      <rect x="64.80595" y="277" width="350" height="243" fill="#3f3d56" />
      <rect x="92.80595" y="301" width="46" height="46" fill="#f2f2f2" />
      <rect x="92.80595" y="376" width="46" height="46" fill="#38d39f" />
      <rect x="92.80595" y="451" width="46" height="46" fill="#f2f2f2" />
      <rect x="160.80595" y="315.5" width="219" height="4" fill="#f2f2f2" />
      <rect x="160.80595" y="328.5" width="68" height="4" fill="#f2f2f2" />
      <rect x="160.80595" y="390.5" width="219" height="4" fill="#f2f2f2" />
      <rect x="160.80595" y="403.5" width="68" height="4" fill="#f2f2f2" />
      <rect x="160.80595" y="465.5" width="219" height="4" fill="#f2f2f2" />
      <rect x="160.80595" y="478.5" width="68" height="4" fill="#f2f2f2" />
      <path
        d="M1028.903,769.39173c0,20.35636-12.09988,27.46356-27.02822,27.46356-.34578,0-.69155-.005-1.03485-.01246-.69157-.01492-1.37816-.04725-2.05479-.09451-13.47306-.95277-23.93856-8.42565-23.93856-27.35659,0-19.58768,25.03064-44.30737,26.91626-46.14076l.00249-.00249c.07215-.07213.10945-.107.10945-.107S1028.903,749.03785,1028.903,769.39173Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#38d39f"
      />
      <path
        d="M1000.88966,793.74075l9.88588-13.81138-9.91077,15.32884-.02487,1.58462c-.69157-.01492-1.37816-.04725-2.05479-.09451l1.0647-20.36384-.00745-.1592.01741-.02737.102-1.92543-9.93562-15.36616,9.96547,13.92333.02488.408.80351-15.38607-8.50525-15.8786,8.60972,13.17952.83834-31.90151.00249-.10945v.107l-.13931,25.155,8.46794-9.97295-8.50277,12.1397-.22388,13.77655,7.9082-13.22182-7.94054,15.24924-.12438,7.65945,11.47549-18.40357-11.52027,21.0753Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#3f3d56"
      />
      <path
        d="M151.903,630.39173c0,20.35636-12.09988,27.46356-27.02822,27.46356-.34578,0-.69155-.005-1.03485-.01246-.69157-.01492-1.37816-.04725-2.05479-.09451-13.47306-.95277-23.93856-8.42565-23.93856-27.35659,0-19.58768,25.03064-44.30737,26.91626-46.14076l.00249-.00249c.07215-.07213.10945-.107.10945-.107S151.903,610.03785,151.903,630.39173Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#38d39f"
      />
      <path
        d="M123.88966,654.74075l9.88588-13.81138-9.91077,15.32884-.02487,1.58462c-.69157-.01492-1.37816-.04725-2.05479-.09451l1.0647-20.36384-.00745-.1592.01741-.02737.102-1.92543-9.93562-15.36616,9.96547,13.92333.02488.408.80351-15.38607-8.50525-15.8786,8.60972,13.17952.83834-31.90151.00249-.10945v.107l-.13931,25.155,8.46794-9.97295-8.50277,12.1397-.22388,13.77655,7.9082-13.22182-7.94054,15.24924-.12438,7.65945,11.47549-18.40357-11.52027,21.0753Z"
        transform="translate(-39.09703 -100.4591)"
        fill="#3f3d56"
      />
    </svg>
  </div>
)

export const HamburgerIcon = ({ className = "" }) => (
  <div className={className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path fill="white" d="M0 0h24v24H0V0z" />
      <path d="M2 16v2h20v-2H2zm0-5v2h20v-2H2zm0-5v2h20V6H2z" />
    </svg>
  </div>
)
