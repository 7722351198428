import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Helmet from "react-helmet"

// import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from "gatsby-background-image"
import ButtonLink from "../components/buttonLink"
import { ExperienceDesign, CreativeTeam } from "../components/svgs"
// import { InfoContainer } from "../components/infoContainer"
import NavBar from "../components/navbar"

import OurServices from "../components/OurServices"
import WeCanHelp from "../components/WeCanHelp"

class Index extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    const fluid = this.props.data.background.childImageSharp.fluid
    const logoFull = this.props.data.borealisLogoFull.childImageSharp.fluid
    const logoText = this.props.data.borealisLogoText.childImageSharp.fluid
    const logoOnly = this.props.data.borealisLogoOnly.childImageSharp.fluid

    return (
      <Layout>
        <Helmet>
          <title>Borealis | Home</title>
        </Helmet>
        <NavBar></NavBar>
        <BackgroundImage className="index__splash" fluid={fluid}>
          <SEO title="Home" />

          <div className="index__title">
            <Img className="index__logoFull" fluid={logoFull} />
            <Img className="index__logoOnly" fluid={logoOnly} />
            <Img className="index__logoText" fluid={logoText} />
          </div>
          <div className="index__taglineContainer">
            <div className="index__tagline">
              Let's build
              <br />
              something amazing
            </div>
            {/* <button className="index__taglineButton">Learn More</button> */}
            <ButtonLink
              buttonText="Learn More"
              linkTo="#moreInfo"
              className="index__learnMoreButton"
            />
          </div>
        </BackgroundImage>
        <div className="index__moreInfoSection" id="moreInfo">
          <WeCanHelp id="howWeHelp" />
        </div>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    background: file(relativePath: { eq: "Borealis_background.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    borealisLogoFull: file(relativePath: { eq: "BorealisLogo_full.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    borealisLogoText: file(relativePath: { eq: "BorealisLogo_text.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    borealisLogoOnly: file(relativePath: { eq: "BorealisLogo_only.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
