import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { ButtonStyled } from "./CustomComponents"

// const ButtonStyled = styled.button`
//   border: 2px solid white;
//   border-radius: 30px;
//   color: white;
//   background-color: var(--background-color1);
//   font-size: 25px;
//   padding: 10px 20px;
//   width: fit-content;
//   justify-self: end;
//   transition: all 0.2s ease-in-out;

//   :hover {
//     background-color: var(--color1);
//     cursor: pointer;
//     transform: scale(1.2);
//     border: none;
//   }
// `

const ButtonLink = ({
  buttonText = "button text",
  linkTo = "#",
  className,
}) => {
  // set default class for button
  let classes = "buttonLink__container"
  // if a class name is provided, add it on
  if (!!className) {
    classes += ` ${className}`
  }
  return (
    <Link to={linkTo} className={classes}>
      <ButtonStyled>{buttonText}</ButtonStyled>
    </Link>
  )
}

export default ButtonLink
