import React from "react"
import styled from "styled-components"

import ButtonLink from "./buttonLink"
import { InfoContainer, TextEmph, Below } from "./CustomComponents"
import { ExperienceDesign } from "./svgs"

// import { ExperienceDesign } from "./svgs"

const InfoGraphic = styled(ExperienceDesign)`
  justify-self: center;
  margin: 55px 0;
  max-width: 400px;
  /* width: 30%; */
  height: auto;
`

// const Container = styled(InfoContainer)`
//   /* display: grid; */
//   /* grid-template-columns: 1fr 1fr; */
// `

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  div {
    justify-self: center;
  }

  ${Below.med`
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
    text-align: center; 
  `}
`

const WeCanHelp = () => {
  return (
    <InfoContainer>
      {/* <ExperienceDesign className="index__infoGraphic" /> */}
      <GridContainer>
        <div>
          <h2>How we can help</h2>
          <InfoGraphic />
        </div>
        <div>
          <p style={{ marginBottom: "25px" }}>
            We offer engaging user experiences through{" "}
            <TextEmph>clean, responsive, and functional</TextEmph> web design.
          </p>
          <p style={{ marginBottom: "25px" }}>
            Working in collaboration with our clients we create{" "}
            <TextEmph>bespoke websites and web applications</TextEmph> that are
            as unique as your organization.
          </p>
          <ButtonLink buttonText="Our Services" linkTo="/services" />
        </div>
      </GridContainer>
    </InfoContainer>
  )
}

export default WeCanHelp
